import { render, staticRenderFns } from "./interactions-card.vue?vue&type=template&id=1744a410&scoped=true"
import script from "./interactions-card.vue?vue&type=script&lang=js"
export * from "./interactions-card.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1744a410",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InteractionsTable: require('/codebuild/output/src3357764168/src/web/components/interactions/interactions-table.vue').default,ConfirmModal: require('/codebuild/output/src3357764168/src/web/components/confirm-modal.vue').default})
