import { render, staticRenderFns } from "./history-modal.vue?vue&type=template&id=219c9240&scoped=true"
import script from "./history-modal.vue?vue&type=script&lang=js"
export * from "./history-modal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "219c9240",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EditingHistory: require('/codebuild/output/src3357764168/src/web/components/editing-history.vue').default})
