


import moment from "moment/moment";
import TableFilters from "../table-filters.vue";
import ContactName from "../contacts/contact-name.vue";
import DownloadSelectColumnsModal from "../download-select-columns-modal.vue";
import ContactBulkOperationModal from "../contacts/contact-bulk-operation-modal.vue";
import modals from "../../mixins/modals";
import fileDownload from "../../mixins/file-download";
import {ATTENDANCE_OPTIONS} from "../../mixins/attending";
import {INVESTOR_TARGET_REASON} from "../../mixins/investor_target_reasons";
import {TIER_OPTIONS} from "../../mixins/contact-tiers";
import QuintableSearchInput from "../helpers/quintable-search-input.vue";
import emailPopoverActionsMixin from "~/mixins/email-popover-actions";

export default {
    name: "InvestorTargetsTable",
    components: {
        QuintableSearchInput,
        DownloadSelectColumnsModal,
        ContactBulkOperationModal,
        ContactName,
        TableFilters,
    },
    mixins: [
        modals,
        fileDownload,
        emailPopoverActionsMixin
    ],
    props: {
        event: {
            type: Object,
            default: null
        },
        companyId: {
            type: Number,
            required: false,
            default: null
        },
        companyHash: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            isLoading: false,
            rows: [],
            selectedRows: [],
            preSelectedRows: [],
            preSelectedRowIds: [],
            bulkTriggered: false,
            bulkUpdated: moment().unix(),
            tableUpdated: this.generateUUID(),
            bulkModal: null,
            downloadQuery: null,
            pageChanged: false,
            currentPage: 1,
            pagination: 25,
            ATTENDANCE_OPTIONS,
            INVESTOR_TARGET_REASON,
            selectedContactTypes: [],
            selectedInvestorTiers: [],
            selectedReasons: [],
            contactTypeOptions: [],
            sortOrder: null,
            ajaxRows: [],
            ajaxAll: 0,
            axiosInterceptor: null,
            contactHash: null,
            TIER_OPTIONS
        }
    },
    computed: {
        config() {
            return {
                columns: [
                    {
                        headline: "Name",
                        sort: true
                    },
                    {
                        headline: "Contact Type",
                    },
                    {
                        headline: "Investor Company",
                    },
                    {
                        headline: "Company",
                        hidden: this.companyId > 0,
                    },
                    {
                        headline: "Reasons",
                        sort: true
                    },
                    {
                        headline: "Is Attending",
                        hidden: !this.event,
                    },
                    {
                        headline: "Has Attended",
                        hidden: !this.event || this.event?.isFuture,
                    },
                ],
                pagination: this.pagination,
                select: true,
                selectPosition: "pre",
                selectAll: true,
                prettySelect: true,
                search: true,
                ajaxUrl: this.companyId > 0 ? `/api/events/investor-target?companyId=${this.companyId}` : `/api/events/investor-target?hash=${this.companyHash}`,
            }
        },
        bulkFilters() {
            switch (this.mode) {
                case "selected":
                    return {
                        ids: this.preSelectedRowIds
                    }
                // case "page":
                //     return {
                //         ids: this.ajaxRows.map(function (row) {
                //             return row.contact_id;
                //         }),
                //     }
                case "filtered":
                    return {
                        hash: this.contactHash,
                    }
                default:
                    // if (this.searchQuery) {
                    //     return {...this.filters, 'search_term': this.searchQuery}
                    // }
                    return {
                        ids: this.ajaxRows.map(function (row) {
                            return row.contact_id;
                        }),
                    };
            }
        },
        filters() {
            return {
                eventId: this.event ? this.event.id : null,
                contactTypes: this.selectedContactTypes.length > 0 ? this.selectedContactTypes.map(ct => ct.id) : null,
                investorTiers: this.selectedInvestorTiers.length > 0 ? this.selectedInvestorTiers.map(t => t.value) : null,
                reasons: this.selectedReasons.length > 0 ? this.selectedReasons.map(ct => ct.value) : null,
            }
        }
    },
    watch: {
        selectedRows(rows) {
            if (!this.pageChanged && this.ajaxRows) {
                for (let i = 0; i < rows.length; i++) {
                    if (!this.preSelectedRowIds.includes(rows[i].contact_id)) {
                        this.preSelectedRowIds.push(rows[i].contact_id);
                    }
                }

                for (let j = 0; j < this.ajaxRows.length; j++) {
                    const id = this.ajaxRows[j].contact_id;

                    const index = this.preSelectedRowIds.indexOf(id);

                    if (!rows.map(r => r.contact_id).includes(id) && index !== -1) {
                        this.preSelectedRowIds.splice(index, 1);
                    }
                }
            }
        },
        filters: {
            handler() {
                this.pageChanged = true;
            },
            deep: true,
        },
    },
    mounted() {
        this.resetFilters();
    },
    created() {
        this.loadContactTypeOptions();
        this.axiosInterceptor = this.$axios.interceptors.response.use((response) => {
            if (response.config.url.includes('/api/events/investor-target?')) {
                if (response.data.hasOwnProperty('contactHash')) {
                    this.contactHash = response.data.contactHash;
                    return response;
                }
            }

            this.contactsHash = null;
            return response;
        });
    },
    beforeDestroy() {
        if (this.axiosInterceptor) {
            this.$axios.interceptors.response.eject(this.axiosInterceptor);
        }
    },
    methods: {
        onRowsUpdated(data) {
            this.pageChanged = false;

            this.ajaxRows = data.rows;
            this.ajaxAll = data.all;

            this.preSelectedRows = JSON.parse(JSON.stringify(this.preSelectedRowIds)).map(id => {
                return {
                    key: "contact_id",
                    value: id
                }
            });
        },
        onPageChange(page) {
            this.currentPage = page;
            this.pageChanged = true;
        },
        clearPreSelection() {
            this.preSelectedRowIds = [];
            this.preSelectedRows = [];
        },
        openBulkOperationModal(mode) {
            this.mode = mode;
            this.bulkUpdated = moment().unix();
            this.bulkTriggered = true;

            this.$nextTick(() => {
                this.bulkModal = this.openModal(this.$refs.contactBulkOperationModal)
            })
        },
        onBulkSubmitted(update = true) {
            if (update) {
                this.tableUpdated = this.generateUUID();
            }
            this.closeModal(this.bulkModal);
        },
        downloadBulkContacts() {
            this.closeModal(this.bulkModal);

            switch (this.mode) {
                case "selected":
                    this.downloadQuery = {
                        filters: {
                            ids: this.preSelectedRowIds
                        },
                    };
                    break;
                case "filtered":
                default:
                    this.downloadQuery = {
                        filters: {
                            ids: this.rows.map(function (row) {
                                return row.contact_id;
                            }),
                        },
                    };
            }

            this.openModal(this.$refs.downloadContactsModal);
        },
        loadContactTypeOptions() {
            this.$axios.get('/api/contact_types?isInvestor=1')
                .then((response) => {
                    this.contactTypeOptions = response.data['hydra:member'];
                });
        },
        resetFilters() {
            this.selectedContactTypes = [];
            this.selectedInvestorTiers = [];
            this.selectedReasons = this.INVESTOR_TARGET_REASON.filter(r => r.default);
        },
        sortUpdate(order) {
            this.sortOrder = order;
            this.pageChanged = true;
        },
        downloadInvestorTargets() {
            const query = {
                // search: null,
                filters: this.filters,
                sort: this.sortOrder,
            }
            this.saveQueryAndExport(
                query,
                // '/api/investor-target/excel_export/' + this.companyId
                this.companyId > 0 ? `/api/investor-target/excel_export?companyId=${this.companyId}` : `/api/investor-target/excel_export?hash=${this.companyHash}`
            );

        },
        clearReasons() {
            this.selectedReasons = [];
        },
    }
}
